const searchSubmitButton =
  document.querySelectorAll<HTMLButtonElement>(".search-bar button");
const searchInput =
  document.querySelector<HTMLInputElement>(".search-bar__input");
if (!searchInput) throw new Error("Could not find search input");

const updateButtons = () => {
  searchSubmitButton.forEach((i) => {
    i.disabled = !searchInput.value;
  });
};

updateButtons();
searchInput.addEventListener("keyup", updateButtons);
